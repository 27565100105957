import * as React from 'react';
import Link from 'gatsby-link';
import styled from 'styled-components';
import { Container, desktop, tablet, mobile, tiny } from './container';

export type GameDetails = {
    category: 'Word' | 'Card' | 'Other',
    name: string,
    playersRequired: string,
    playersRecommended: string,
    pageName: string,
    imageUrl: string,
    imageName: string,
    imageAuthor: string,
    setup?: React.ReactNode,
    howToPlay: React.ReactNode,
    metaDescription: string,
};

type DummyGameDetails = {
    playersRecommended: string,
    imageUrl: string,
    imageName: string,
    imageAuthor: string,
    setup?: React.ReactNode,
    howToPlay: React.ReactNode,
};

export const dummyDetails: DummyGameDetails = {
    playersRecommended: '?',
    imageUrl: '?',
    imageName: '?',
    imageAuthor: '?',
    howToPlay: <div>TODO</div>,
};

type GameProps = {
    gameDetails: GameDetails,
};

export const GameSummary = ({ gameDetails }: GameProps) => (
    <SummaryWrapper to={`/DrinkingGames/${gameDetails.pageName}`}>
        <SummaryHeader>
            <GameIcon src={require(`../images/icons/gameIcons/${gameDetails.pageName}.svg`)} alt={`${gameDetails.imageName}.`} />
            <NameAndPlayers>
                <GameName>{gameDetails.name}</GameName>
                <PlayersWrapper>
                    <PlayersIcon src={require('../images/users-solid.svg')} alt="Players." />
                    <PlayersRequired>{gameDetails.playersRequired}</PlayersRequired>
                </PlayersWrapper>
            </NameAndPlayers>
        </SummaryHeader>
        <SummaryText>
            {gameDetails.metaDescription}
        </SummaryText>
    </SummaryWrapper>
);

const SummaryWrapper = styled(Link)`
    display: inline-block;
    margin-bottom: 15px;
    margin-right: 15px;
    padding: 15px 20px;
    background-color: rgba(255, 255, 255, 0.75);
    border: solid 1px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease;
    opacity: 0.8;
    text-decoration: none;
    color: black;

    &:hover {
        opacity: 1;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    }

    width: 450px;

    @media (max-width: ${desktop}px) {
        width: auto;
        margin-bottom: 15px;
        margin-right: 0;
    }
`;

const SummaryHeader = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: no-wrap;
    margin-bottom: 25px;
`;

const GameIcon = styled.img`
    height: 50px;
    margin-right: 15px;
    border-radius: 10px;
    padding: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

    @media (max-width: ${mobile}px) {
        height: 65px;
        margin-right: 15px;
    }
`;

const NameAndPlayers = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    flex: 1;
`;

const GameName = styled.span`
    font-size: 1.3em;
    margin-right: 8px;

    @media (max-width: ${mobile}px) {
        font-size: 1.1em;
    }
`;

const PlayersWrapper = styled.div`
    background-color: rgba(0, 0, 0, 0.05);
    padding: 5px 10px;
    border-radius: 15px;

    @media (max-width: ${mobile}px) {
        margin-top: 15px;
    }
`;

const PlayersIcon = styled.img`
    height: 20px;
    margin-right: 10px;
    opacity: 0.7;
`;

const PlayersRequired = styled.span`
    font-weight: bolder;
    position: relative;
    bottom: 5px;
    color: black;
    margin-right: 2px;
    color: #333;
`;

const SummaryText = styled.p`
    line-height: 1.6em;
    font-size: 0.9em;
`;
